/* eslint-disable prettier/prettier */
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import UserInterface from "../../app/models/user-interface"
import { AppThunk } from "../../app/store"
import { fetchUsers } from "../../app/services/users.service"

export interface UsersListState {
  users: UserInterface[]
  loading: boolean
}

const userListInitState: UsersListState = {
  users: [],
  loading: false,
}

export const fetchUsersThunk = createAsyncThunk( "usersList/fetchUsers", async () => {
    const response = await fetchUsers()
    return response
  },
)

export const usersListSlice = createSlice({
  name: "usersList",
  initialState: userListInitState,
  reducers: {
    setUserList: (state, action: PayloadAction<UserInterface[]>) => {
      state.users = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUsersThunk.fulfilled, (state, action) => {      
      state.users = action.payload
    })
  },
})

export const { setUserList } = usersListSlice.actions

export const getUserList = (): AppThunk => async (dispatch, getState) => {}

export default usersListSlice.reducer
