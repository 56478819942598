/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Box from "@mui/material/Box"
import AppBarComponent from "./components/app-bar-component"
import AppDrawer from "./components/app-drawer"
// import DashboardContent from "./components/dashboard-content"
import { Outlet, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import ModalAlertComponent from "../../app/components/modal-alert-component"
import { checkUserAtLocalStorageAction } from "../signin/signin.slice"

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme()

export default function Dashboard() {
  const { alerts } = useAppSelector((state: RootState) => state.dashboard)
  const dispatch = useAppDispatch()
  const [open, setOpen] = React.useState(true)
  const navigate = useNavigate()
  const { currentUser, endSession, endSessionForce } = useAppSelector(
    (state: RootState) => state.login,
  )

  React.useEffect(() => {
    if ((currentUser === undefined && endSession) || endSessionForce === true) {
      navigate("/logout")
    }
  }, [currentUser, endSession, endSessionForce])

  React.useEffect(() => {
    dispatch(checkUserAtLocalStorageAction())
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex", width: "100%" }}>
        {alerts.map((el, i) => {
          return (
            <ModalAlertComponent
              key={"Alert999" + i}
              title={el.title}
              description={el.message}
              index={i}
              modalAlertState={el}
            />
          )
        })}
        <AppDrawer />
        {/* <CssBaseline /> */}
        {/* <DashboardContent /> */}
        <Box flexGrow={1}>
          <AppBarComponent open={open} setOpen={setOpen} />
          <Box padding={2}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
