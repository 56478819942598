import { createBrowserRouter } from "react-router-dom"
import UsersList from "../features/users-list/UsersList"
import { HandleUser } from "../features/handle-user/HandleUser"
import Dashboard from "../features/dashboard/Dashboard"
import { HandleCard } from "../features/handle-card/handle-card"
import SignInView from "../features/signin/signin"
import LogoutView from "../features/logout/logout"
import { CardsList } from "../features/cards-list/cards-list"
import HandleExpense from "../features/handle-expenses/handle-expenses"
import HistoryCards from "../features/history-cards/history-cards"
import CapitalContribute from "../features/capital-contribute/capital-contribute"
import ClosureView from "../features/closure/closure-view"

const router = createBrowserRouter([
  { path: "/", element: <SignInView /> },
  { path: "", element: <SignInView /> },
  { path: "/login", element: <SignInView /> },
  { path: "/logout", element: <LogoutView /> },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      { path: "user-list", element: <UsersList /> },
      { path: "handle-user", element: <HandleUser /> },
      { path: "handle-user/:userId", element: <HandleUser /> },
      { path: "create-card", element: <HandleCard /> },
      { path: "cards-lists", element: <CardsList /> },
      { path: "cards-history", element: <HistoryCards /> },
      { path: "expenses", element: <HandleExpense /> },
      { path: "closure", element: <ClosureView /> },
      { path: "closure/:userId", element: <ClosureView /> },
      { path: "capital-contribute", element: <CapitalContribute /> },
      { path: "cards-lists/:userId", element: <CardsList /> },
      { path: "handle-card/:cardId", element: <HandleCard /> },
    ],
  },
])

export default router
