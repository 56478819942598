import Api from "../axios"

const api = new Api()

export async function addPaymentReq(data: { cardId: string; value: number }) {
  try {
    const addPayment = await api.post({
      path: `payments/add-payment/`,
      data: { ...data, value: Number(data.value) },
    })
    console.log({ addPayment })
    const { error } = addPayment
    if (error == null) {
      if (addPayment.result.newPayment !== undefined) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (error) {
    throw error
  }
}

