/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import { Box, Button,} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import LoadingIndicator from "../../app/components/loading-indicator"
import ExpenseFormModal from "./components/expense-form-modal"
import { fetchUserExpenseThunk, showModalFormExpenseAction, sumTotalExpensesAction } from "./handle-expenses.slice"
import { useEffect } from "react"
import ExpensesListComponent from "./models/expenses-list-component"

export default function HandleExpenses() {
  const dispatch = useAppDispatch()
  const { expenses, loading} = useAppSelector((state: RootState) => state.expenses)
  useEffect(() => {
    dispatch(fetchUserExpenseThunk())
  }, [])

  useEffect(() => {
    dispatch(sumTotalExpensesAction())
  }, [expenses])
  return (
    <>
      <LoadingIndicator open={loading} />
      <ExpenseFormModal />
      <Box marginBottom={3}>
        <Button variant="contained" color="success" onClick={() => dispatch(showModalFormExpenseAction(true))} > AGREGAR GASTO</Button>
      </Box>
      <ExpensesListComponent />
    </>
  )
  
}