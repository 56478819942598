/* eslint-disable prettier/prettier */
import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import PeopleIcon from "@mui/icons-material/People"
import AssignmentIcon from "@mui/icons-material/Assignment"
import { Link } from "react-router-dom"
import { AddBusiness, Analytics, List, Logout, Margin, PersonAdd, PriceChange } from "@mui/icons-material"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"

interface RouteItemI {
  to: string
  Icon: any
  title: string
}

export function MenuItems() {
  
  const {currentUser} = useAppSelector((state: RootState) => state.login)
  
  
  const mapRoutes: RouteItemI[] = [
    { to: "/dashboard/user-list", Icon: <PeopleIcon />, title: "Users" },
    { to: "/dashboard/handle-user", Icon: <PersonAdd />, title: "Crear usuario" },
    { to: "/dashboard/create-card", Icon: <Margin />, title: "Crear Tarjeta" },
    { to: "/dashboard/cards-lists", Icon: <List />, title: "Lista" },
    { to: "/dashboard/expenses", Icon: <PriceChange />, title: "Gastos" },
    { to: "/dashboard/capital-contribute", Icon: <AddBusiness />, title: "Aporte Capital" },
    { to: "/dashboard/closure", Icon: <Analytics />, title: "Cuadre" },
    // { to: "/logout", Icon: <Logout />, title: "Salir" },
  ]
  
  const mapRoutesVentor: RouteItemI[] = [
    { to: "/dashboard/create-card", Icon: <Margin />, title: "Crear Tarjeta" },
    { to: "/dashboard/cards-lists", Icon: <List />, title: "Lista" },
    { to: "/dashboard/cards-history", Icon: <List />, title: "Historial" },
    { to: "/dashboard/expenses", Icon: <PriceChange />, title: "Gastos" },
    { to: "/dashboard/closure", Icon: <Analytics />, title: "Cuadre" },
    { to: "/dashboard/capital-contribute", Icon: <AddBusiness />, title: "Aporte Capital" },
    // { to: "/logout", Icon: <Logout />, title: "Salir" },
  ]

  return (
    <React.Fragment>
        {(currentUser?.level === 0 ? mapRoutes : mapRoutesVentor).map((el, i) => {
        return (
          <Link key={"mainMenu" + i} to={el.to}>
            <ListItemButton>
              <ListItemIcon>{el.Icon}</ListItemIcon>
              <ListItemText primary={el.title} />
            </ListItemButton>
          </Link>
        )
      })}
    </React.Fragment>
  )
}

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
)
