/* eslint-disable prettier/prettier */
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Card } from "@mui/material"
import { useAppSelector } from "../../../app/hooks"
import { RootState } from "../../../app/store"

export default function ExpensesListComponent() {
  const {expenses, total} = useAppSelector((state: RootState) => state.expenses)

  return(
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Descp</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.map((el, i) => {
              return(
                <TableRow key={`${el._id}`}>
                  <TableCell>{el.date}</TableCell>
                  <TableCell>{el.name}</TableCell>
                  <TableCell>{el.description}</TableCell>
                  <TableCell>$ {el.value}</TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell align="center" colSpan={3}>Total: </TableCell>
              <TableCell colSpan={2}>$ {total}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )

}