import { TextField } from "@mui/material"

export interface InputAppProps {
  placeholder: string
  readonly?: boolean
  value: string
  onChange: (val: any, name: string) => void
  name: string
  disabled?: boolean
}

export default function InputApp({
  name,
  onChange,
  placeholder,
  readonly = false,
  value,
  disabled = false,
}: InputAppProps) {
  return (
    <TextField
      style={{ marginBlock: 10 }}
      fullWidth
      id={name}
      name={name}
      disabled={disabled}
      //type={key === "date" ? "date" : "string"}
      label={name.toUpperCase()}
      value={value}
      onChange={(e) => onChange(e.target.value, name)}
    />
  )
}
